import React from 'react';
import MobileStoreButton from 'react-mobile-store-button';
 
export default class AppButtons extends React.Component {
    render() {
        const iOSUrl = 'https://apps.apple.com/us/app/cambridge-explorer/id1581282916';
        const playUrl = 'https://play.google.com/store/apps/details?id=com.cambridgeexplorer.app';
        return (
            <div className='buttonContainer'>
            <div className='exButton' style={{textAlign:'right'}}>
                
                <a href={iOSUrl} ><img className="app_badge" src='badge_appstore-lrg.svg' /></a>
            </div>

            <div className='exButton' style={{textAlign:'left'}}>
                
                <a href={playUrl} ><img className='app_badge' src='google-play-badge.png' /></a>

            </div>
            </div>
        );
    }
}
 