import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Home } from './Home';
import { Privacy } from './Privacy';
import { ThirdParty } from './ThirdParty';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from 'react-router-dom';

import { Support } from './Support';
import { Guidelines } from './Guidelines';
import reportWebVitals from './reportWebVitals';

const routes = (

  <Router>
  <App>
    <Routes>
      <Route exact path='/' element={< Home />}></Route>
      <Route exact path='/support.html' element={< Support />}></Route>
      <Route exact path='/support' element={< Support />}></Route>
      <Route exact path='/privacy.html' element={< Privacy />}></Route>
      <Route exact path='/privacy' element={< Privacy />}></Route>
      <Route exact path='/thirdparty.html' element={< ThirdParty />}></Route>
      <Route exact path='/thirdparty' element={< ThirdParty />}></Route>
      <Route exact path='/guidelines.html' element={< Guidelines />}></Route>
      <Route exact path='/guidelines' element={< Guidelines />}></Route>
   </Routes>
   </App>

   </Router>
  
);

ReactDOM.render(routes,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
