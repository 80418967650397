import logo from './logo.svg';
import './App.css';
import AppButtons from './Buttons';
function App({children}) {
  return (
    <div className="App">

        <header>
            <div className="banner">
              <img className="mainlogo" src="./feature_small.png" />
            </div>
        </header>


      {children}

          
     <footer>
       <p>Copyright &copy; Cambridge Explorer 2022</p>
     <a href="/privacy.html">Privacy Policy</a> | <a href="/support.html">Support</a> |  <a href="/guidelines.html">Community Guidelines</a> 
     </footer>
    </div>
  );
}

export default App;
