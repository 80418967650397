import './App.css';
export function Guidelines() {

    return (
        <div className="content_page">
                  <h1>Community Guidelines</h1>


<p>As a participant in the community features on the Cambridge Explorer app you agree that you will uphold and comply with these community guidelines, and are responsible for all activities and content that you post.</p>


<p>Cambridge Explorer reserves the right, at its sole discretion and option, to remove inappropriate language and postings. In such instances, the user will be warned and may be banned from future participation, particularly in the event of repeat occurrences.</p>

<p>You agree to observe the following guidelines:</p>

<ol>
    <li>The review features on this app are not intended to serve as an online shopping or trading forum. No commercial solicitation, advertising, or promotional activities for your company or business.</li>

    <li>No reviews or usernames shall contain profanity (implied or otherwise), or hate language targeting race / ethnicity, religion, gender, nationality, or political beliefs.</li>

    <li>No personal threats or attacks will be tolerated against users, employees, business owners, or any other individuals. Inflammatory or unnecessarily argumentative postings will be considered trolling.</li>
    
    <li>No posting of private or confidential information.</li>

    <li>No online campaigning.</li>
    
    <li>Do not register under multiple IDs, or utilize multiple email addresses. </li>
    
</ol>
        </div>

    )

}

