import './App.css';
export function Privacy() {

    return (
        <div className="content_page">
        <h1>Privacy Policy</h1>
   

        <h2>Overview</h2>

<p>Cambridge Explorer is an information services app that provides users with suggestions and reviews of local and independent businesses within Cambridge and the surrounding area. </p>

<p>We provide a range of services through the Cambridge Explorer app. Because the range of activities in Cambridge is always changing and evolving, these services are provided over the web.  Making web requests exposes a minimal amount of information, similar to viewing a website, as requests must be processed by our servers and returned to you.</p>

<p>Some features in Cambridge Explorer require detailed information. For example, to provide location-based suggestions, the application will require access to your location. These enhanced features are entirely opt-in and can be accessed at the users' disgression. We understand the need to secure this data and take precautions on how we process this information, outlined subsequently in this document.</p>

<p>Community features in Cambridge Explorer, such as leaving reviews or reacting to places, will share a limited amount of information that you choose to provide. These services require authentication (i.e. you have to be logged in) so that we can provide a reputable community of genuine reviews.</p>

<p>In this privacy policy, these three feature levels will be referred to as <strong>basic features</strong>, <strong>enhanced features</strong> and <strong>community features</strong>. </p>
    
<h2>Information you provide</h2>


<h3>Basic features</h3>
<p>Wishlists and visited places are stored locally on your phone and are not stored on Cambridge Explorer's servers. If you wish to keep these safe, we recommend backing up the app's data on your phone using iCloud or Android.</p>

<p>When you use the search functionality in the app, what you search for is sent to Cambridge Explorer's servers. Our servers will provide the best matching places for what your search query. We collect logs of what is searched for and use this for the purposes of recommending the best locations for your query and understanding trends.</p>


<h3>Enhanced features</h3>
<p>To provide location-based suggestions, Cambridge Explorer requires access to your location. You can choose to provide this by opting in and pressing "enable location-based suggestions" at the top of the app. You can opt-out again by disabling location services for Cambridge explorer.</p>

<p>Location data needs to be processed by Cambridge Explorer's servers for the purposes of providing location-based suggestions. This data is not stored.</p>

<h3>Community features</h3>
<p>When you leave reviews or react to places on the Cambridge Explorer, the content you provide will be shared with other users on the app publicly.  To use the community features, you must authenticate yourself by signing in with either your Apple or Google account. Cambridge Explorer will never ask you for a password.</p>

<p>When you authenticate yourself, Camrbdige Explorer may collect basic personally identifiable information such as your email address and name. This information is collected for the purposes of providing the community features on the app and ensuring that genuine users are posting content on the app.</p>

<p>When you post reviews, they will be listed under your <em>display name</em> which you can choose to set, or leave blank for anonymous reviews. The display name will be posted alongside the content of your reviews after the review has been approved for publication. All reviews are subject to review and may be refused or redacted if your review violates the community guidelines.</p>


<h2>Information we collect</h2>

<p>When you use our services, we collect information about the apps and devices that you use. This helps us provide some features, such as tailoring the images and content to be appropriate for your screen size.</p>

<p>We collect this information whenever the Cambridge Explorer app makes a connection to the Cambridge Explorer servers. This information includes your IP address, crash reports, unique identifiers, device type and, operating system. </p>

<h3>Activities</h3>
<p>We collect information about your activity on Cambridge Explorer, so that we can understand trends for places, and recommend more appropriate places for you to visit. This may include:</p>

<ul>
    <li>Which places you open on the app</li>
    <li>How you interact with, or react to, or review places, such as using the community features</li>
    <li>What you search for on the app</li>
</ul>

<h2>How your information is used</h2>
<p>We use the information you provide, or that we collect to: provide our services, maintain these services, develop new services, provide personalised experiences, measure performance, protect ourselves and our users.  </p>

<p>We may use this information to communicate with you and send you an email or notification if we detect suspicious activity or activities that violate our community guidelines. Additionally, if you communicate with Cambridge Explorer, we will keep a record of this to help ensure any subsequent communication is provided with appropriate context.</p>


<h2>Choose how we use your data</h2>
<p>After you have opted in, you can opt-out of community features by signing out of your account on the settings page of the app. You can opt-out of enhanced features by disabling location features for the Cambridge Explorer app.</p>

<h3>Right to stop or limit our processing of your data</h3>
<p>You have the right to stop us processing your personal information or to have it deleted. If you wish to exercise this right, please contact us using the information at the end of this policy.</p>

<h3>Information retention</h3>
<p>We may retain your personal information as long as you continue to use the Cambridge Explorer app, or have an account with us, or for as long as is necessary to fulfill the purposes outlined in the policy. You can ask to close your account by contacting us at the details above, and we will delete your information on request.</p>

<p>All personal information and application data is stored and processed by servers located in the United Kingdom.</p>

<h2>Third-party uses of your information</h2>
<p>Crash reports are processed by a third-party company and your data is subject to the terms listed under their privacy policy: <a href="https://sentry.io/privacy">https://sentry.io/privacy</a>. Personal information such as your name and email, location data and application behaviour is not shared and is processed solely by Cambridge Explorer.</p>

<p>This third-party service is based in the United States of America. For personal information that they receive from the European Union and the United Kingdom, this third-party is certified its compliance with the EU-U.S. Privacy Shield as set forth by the U.S. Department of Commerce regarding the collection, use, and retention of personal information from the EU and the UK.</p>

<h2>How to get in contact</h2>
<p>You can contact Cambridge Explorer via email to exercise your rights under GDPR or for any data related queries or concerns. The contact email address is <a href="mailto:support@cambridgeexplorer.zohodesk.eu">support@cambridgeexplorer.zohodesk.eu</a></p>

<h2>Data controller</h2>
<p>The data controller for Cambridge Explorer is James Thorne. The data controller can be contacted by email at <a href="mailto:support@cambridgeexplorer.zohodesk.eu">support@cambridgeexplorer.zohodesk.eu</a></p>
</div>

    )

}

