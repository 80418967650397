import './App.css';
export function Support() {

    return (
        <div className="content_page">
        <h1>Support</h1>
        <p>Get help for Cambridge Explorer, give feedback or ask a question.</p>


        <script src="https://desk.zoho.eu/portal/api/feedbackwidget/80110000000151029?orgId=20080114292&displayType=iframe" />
<iframe id="zsfeedbackFrame" width="890" height="570" name="zsfeedbackFrame" scrolling="no" allowtransparency="false" frameborder="0" border="0" src="https://desk.zoho.eu/support/fbw?formType=AdvancedWebForm&fbwId=edbsn25161b3dd6c88fee2ca4ef5f2ed2b42c225c47bbe45040baf34163ed86756d57&xnQsjsdp=edbsn3c1e6c06b56fbe758aa2b293ac51a833&mode=showNewWidget&displayType=iframe"></iframe>

</div>
    )

}

