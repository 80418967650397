import AppButtons from "./Buttons"

function getRandomInt(max) {
    return Math.floor(Math.random() * max)+1;
  }

export function Home() {
    return (
        <div>

        <div className="noise fullimg" 
        style={{
            backgroundImage: "url(img"+ getRandomInt(5) +".jpeg)"
        }}>
          
          </div>
  
      
          <div style={{display:"flex", flexDirection: "row",
                  marginTop:-460}}>
          <div style={{flex:1,  
                      paddingLeft:"5%",
                      paddingRight:"5%", 
                      flexDirection:'column'}}>
  
                        <div style={{height:460, flexGrow:1}}>
                        <div className="hero">
                        <div className='message'>
                  <h2>Welcome to Cambridge</h2>
            
            </div>
  
  
            </div>
            </div>
  
            <div style={{ display:'flex', justifyContent:'center', minHeight:200, alignItems:"center"}}>
    
                <AppButtons />
            </div>
  
  
            </div>
          <div className="superphone">
    
          <div className="sp-height">
                <img src='phone-template@1x.png'
                    style={{
                      width:"80%",
                      maxWidth:500,
                    }}
                />
              </div>
            
  
          </div>
          </div>
  
  
          <div className="body"  style={{borderTop:"1px solid #ddd",backgroundColor:"#eee", minHeight:400}}>
                
  
              <h2>Make the most of your time in Cambridge</h2>
  
              <p>Cambridge Explorer helps you to choose the best things to do and see in Cambridge. Plan what you want to do, view recommendations from others, and make the most of your time in the city.</p>
  
              <h2>Unique, local and independent</h2>
              <p>Cambridge Explorer shows the best side of Cambridge. We only feature places that are unique, local to Cambridge or independent. All listings are hand-curated by local experts and guaranteed to excite! There are many listings on Cambridge Explorer that you won't find in any other travel guide.</p>
  
              <h2>Find things to do for free</h2>
              <p>Did you know, most of Cambridge's museums are free? Cambridge Explorer helps travelers with limited budgets get a great experience from the city. We list parks, historic streets and free viewpoints that will wow you.</p>
  
              <h2>Available on iOS and Android</h2>
              <p style={{textAlign:'center'}}>Download the app today and explore Cambridge. </p>
  
  
              <div style={{ display:'flex', justifyContent:'center', minHeight:200, alignItems:"center"}}>
    
    <AppButtons />
</div>

  
  
          </div>
        </div>
    )
}